import { Row, Col } from "react-bootstrap";
import { CareersCard } from "./style";
import Button from "../../components/shared/button";

const CareerCard = ({ data }) => {
    return (
        <section className="col-lg-6 mb-4">
            <CareersCard>
                <div className="content">
                    <Row>
                        <Col md={2} sm={1} className="col-2">
                            <i className="icofont-bag-alt icon"></i>
                        </Col>
                        <Col>
                            <span className="text title mb-2">
                                {data.headings[0].content}
                            </span>
                            <span className="mb-4 d-block">
                                {data.experience} Years
                            </span>
                            <div className="number mb-3">
                                Required Skill :- {data.skill}
                            </div>
                            <Button size="medium" path="" shape="rounded5">
                                Apply Now
                            </Button>
                        </Col>
                    </Row>
                    <Row className="d-block"></Row>
                </div>
            </CareersCard>
        </section>
    );
};

export default CareerCard;
