import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "../../components/title";
import { CareerSection, CareerList, CareerinfoList } from "./style";
import CareerCard from "../../components/career-card";

const Careers = ({ data }) => {
    const jobs = data.items;
    return (
        <CareerSection>
            <Container>
                <CareerList>
                    <div>
                        <SectionTitle
                            headingTitle="Openings"
                            showIcon={false}
                            align="left"
                        />
                    </div>
                    <div>
                        <CareerinfoList className="row">
                            {jobs.map((item, index) => {
                                return <CareerCard data={item} key={index} />;
                            })}
                        </CareerinfoList>
                    </div>
                </CareerList>
            </Container>
        </CareerSection>
    );
};
// Careers.propTypes = {
//     brnadAffterImage: PropTypes.object,
//     brnadBeforeImage: PropTypes.object,
//     alt: PropTypes.string,
// };
export default Careers;
