import styled, { device } from "@theme/utils";
export const CareerSection = styled.section`
    padding-bottom: 100px;
`;
export const CareerList = styled.div`
    background-color: #f0f4fc;
    padding: 30px;
    border-radius: 15px;
    ${device.large} {
        padding: 80px;
    }
    .contact-us-title {
        font-size: 25px;
        @media screen and (min-width: 1400px) {
            h2,
            .h2 {
                font-size: 35px;
                line-height: 1;
            }
        }
    }
`;
export const CareerinfoList = styled.div`
    display: flex;
    margin-top: -20px;
    justify-content: space-between;
    ${device.medium} {
        margin-top: -50px;
    }
`;
