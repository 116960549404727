import styled, { themeGet, device } from "@theme/utils";

export const CareersCard = styled.div`
    padding: 25px 40px;
    background-color: white;
    padding: 25px 15px;
    ${device.small} {
        padding: 25px 25px;
    }
    border-radius: 15px;
    display: flex;
    align-items: flex-start;
    &:last-type-of: {
        margin-bottom: 0px;
    }
    height: 100%;

    .content {
        flex: 1 0 0;
        .text {
            line-height: 1;
            display: block;
            text-transform: capitalize;
            font-weight: 700;
            font-size: 15px;
            background: ${themeGet("colors.gradient")};
            background-clip: border-box;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .number {
            line-height: 24px;
            font-weight: 700;
            color: #02126a;
            font-size: 16px;
        }
        .icon {
            font-size: 30px;
            ${device.medium} {
                font-size: 50px;
            }
            ${device.small} {
                font-size: 40px;
            }
        }
        .title {
            font-size: 19px;
            ${device.small} {
                font-size: 25px;
            }
        }
    }
`;
